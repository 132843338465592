<template>
<div>
  <swiper ref="mySwiper" :options="swiperOptions" @slideChange="sendData" @ready="sendData">
    <div class="growing-slide swiper-slide slide-wrapper" v-for="(book, key) in previewBooks" :key="key" :id="id">
      <swiper-slide>
      <div v-if="key === lastBookIndex && activeShelf && isBooksMore" @stop.prevent class="showmore-wrapper">
        <div class="showmore-container">
          <router-link :to="{name: 'ShelfShowMore', params: {id: activeShelf}}">
            <div><button class="btn-black-border w-100 mt-3 mb-1">Zobacz wszystkie</button></div>
          </router-link>
        </div>
      </div>
      <div v-else>
        <router-link :to="{name: 'BooksDetails', params: {id: book.id}}">
          <div class="growing-slide-cover-container books-featured-slide-cover-container">
            <div class="growing-slide-cover selected">
              <img :src="( book.image ? coverUrl + book.image : coverUrl + 'no_cover.png')" class="slide-cover-image" :alt="book.tytul" />
              <div class="bottom-plus-sign" v-if="!hideAdd" @click.prevent="openModal(book)"><i class="fas fa-plus-circle">aa</i></div>
            </div>
          </div>
        </router-link>
      </div>
      </swiper-slide>
    </div>
  </swiper>
  <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
    <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
      <div class="modal-wrapper">
        <div class="growing-slide-cover">
          <img :src="( modal_cover ? coverUrl + modal_cover : coverUrl + 'no_cover.png')" class="slide-cover-image" />
        </div>
        <div class="book-heading-big text-center mt-3">{{modal_author}}</div>
        <div class="book-heading-small text-center mt-2">{{modal_title}}</div>
    </div>
    <router-link :to="{name: 'BorrowBook', params: {id: item.id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
    <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater(item.id)">Zapisz na później</button>
    <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
  </ModalSlot>
</div>
</template>

<script>
import EventBus from '../eventBus'
import ModalSlot from './ModalSlot'
export default {
  name: 'UserProfileSlider',
  props: {
    props: Array,
    shelf: Array,
    activeShelf: [Number, String],
    componentId: String,
    hideAdd: Boolean
  },
  components: {
    ModalSlot
  },
  data () {
    return {
      id: null,
      isBooksMore: false,
      previewBooks: [],
      reversedArray: [],
      lastBookIndex: 0,
      index: 0,
      modal_cover: '',
      item: [],
      modal_title: '',
      modal_author: '',
      shuffleProposed: [],
      isPopupShown: false,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  created () {
    // this.swiper.slideTo(0, 500, true)
    // this.id = this._uid
    this.previewBooks = this.props
    this.limitProposed()
    if (this.activeShelf && this.componentId !== 'proposed') {
      this.previewBooks = []
      this.reversedArray = this.props
      if (this.props.length > 5) {
        for (let i = 0; i < 5; i++) {
          this.previewBooks.push(this.reversedArray[i])
        }
        this.previewBooks.push({
          id: 0,
          image: null,
          autor: ' ',
          tytul: ' ',
          rating: -1,
          autor_id: ' '
        })
        this.isBooksMore = true
      } else {
        for (let i = 0; i < this.props.length; i++) {
          this.previewBooks.push(this.reversedArray[i])
        }
        // this.previewBooks = this.props
        this.isBooksMore = false
      }
      this.lastBookIndex = this.previewBooks.length - 1
    }
    // this.sendData()
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.swiper.slideTo(0, 500, true)
  },
  watch: {
    props: function () {
      this.swiper.slideTo(0, 500, true)
      this.id = this._uid
      this.previewBooks = this.props
      this.limitProposed()
      if (this.activeShelf && this.componentId !== 'proposed') {
        this.previewBooks = []
        this.reversedArray = this.props
        if (this.props.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.previewBooks.push(this.reversedArray[i])
          }
          this.previewBooks.push({
            id: 0,
            image: null,
            autor: ' ',
            tytul: ' ',
            rating: -1,
            autor_id: ' '
          })
          this.isBooksMore = true
        } else {
          for (let i = 0; i < this.props.length; i++) {
            this.previewBooks.push(this.reversedArray[i])
          }
          // this.previewBooks = this.props
          this.isBooksMore = false
        }
        this.lastBookIndex = this.previewBooks.length - 1
      }
      this.sendData()
    }
  },
  methods: {
    limitProposed () {
      if (this.componentId === 'proposed') {
        if (this.props.length > 10) {
          let tempArr = []
          for (let i = 0; i < this.props.length; i++) {
            tempArr.push(i)
          }
          this.previewBooks = []
          this.shuffle(tempArr)
          tempArr = tempArr.slice(0, 10)
          tempArr.forEach(e => {
            this.previewBooks.push(this.props[e])
          })
        }
      } else {
        this.previewBooks = this.props
      }
    },
    shuffle (a) {
      var j, x, i
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        x = a[i]
        a[i] = a[j]
        a[j] = x
      }
      return a
    },
    saveToLater (id) {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
        this.$emit('updated-later')
      })
        .catch(error => {
          console.log('', error)
        })
    },
    previewSlider () {

    },
    openModal (item) {
      this.item = item
      this.modal_cover = item.image
      this.modal_title = item.tytul
      this.modal_author = item.autor
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
    },
    sendData () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
      var el = this.previewBooks[this.slideIndex]
      if (this.previewBooks[0]) {
        let rat = parseFloat(el.rating, 10)
        rat = rat.toFixed(1)
        const payload = {
          autor: el.autor,
          tytul: el.tytul,
          id: el.id,
          rating: parseFloat(rat, 10),
          autor_id: el.author_id
        }
        EventBus.$emit('selected-books-title', payload)

        if (this.componentId) {
          let rat = parseFloat(el.rating, 10)
          rat = rat.toFixed(1)
          const componentPayload = {
            autor: el.autor,
            tytul: el.tytul,
            id: el.id,
            rating: parseFloat(rat, 10),
            autor_id: el.author_id,
            liczba_stron: parseInt(el.objetosc),
            przeczytane_strony: parseInt(el.pages_readed)
          }
          EventBus.$emit('books-in-' + this.componentId, componentPayload)
        }
      } else {
        const payload = {
          autor: '',
          tytul: '',
          id: '',
          rating: -1
        }
        EventBus.$emit('selected-books-title', payload)

        if (this.componentId) {
          const componentPayload = {
            autor: '',
            tytul: '',
            id: '',
            rating: -1
          }
          EventBus.$emit('books-in-' + this.componentId, componentPayload)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-wrapper img {
  max-width: 200px;
  max-height: 300px;
}

.slide-wrapper {
  min-height: 45vw;
}
.showmore-container {
  height: 270px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-wrapper{
  @media screen and (max-width: 320px) {
    height: 240px;
  }
}

.growing-cover-slider .growing-slide .growing-slide-cover-container{
  @media screen and (max-width: 320px) {
    height: 240px
  }
}

.growing-cover-slider .growing-slide.swiper-slide-active .growing-slide-cover, .growing-cover-slider .growing-slide.slick-current .growing-slide-cover{
  @media screen and (max-width: 320px) {
    height: 220px;
    width: 142px;
  }
}

.bottom-plus-sign {
  position: absolute;
  bottom: 0%;
  left: 50%;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-13px, 13px);
  z-index: 5;

  &::before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 26px;
    height: 26px;
    font-size: 26px;
    line-height: 24px;
    color: #000;
    content: '\f055';
    background-color: #fff;
    border-radius: 50%;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    transform: translate(-50%, -50%);
  }
}
</style>
